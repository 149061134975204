import React, { Component } from "react";
import logo from "../logo_transparent.png";

class SecondaryBar extends Component {
  render() {
    return (
      <div className="NavBar2">
        <div className="logo-container2">
          <a href="/">
            <img src={logo} className="NavLogo2" alt="Logo2" />
          </a>
        </div>

        <nav className="nav2">
          <ul className="ul2">
            <li className="li20">
              <a id="li20" href="#About">
                About
              </a>
            </li>
            <li className="li21">
              <a id="li21" href="#Menu">
                Menu
              </a>
            </li>
            <li className="li22">
              <a id="li22" href="#Location">
                Locations
              </a>
            </li>
            <li className="li23">
              <a id="li23" href="#Contact">
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default SecondaryBar;
