import React, { Component } from "react";
import Menu from "../Menu.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import SecondaryBar_Menu from "./SecondaryBar_menu";
import MainBar_Menu from "./MainBar_Menu";

class VanMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      width: 0,
      desktop: false,
      mobile: false,
    };
  }

  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
    });
  };

  render() {
    if (window.innerWidth > 900 && this.state.desktop === false) {
      this.setState({
        desktop: true,
        mobile: false,
        width: "30vw",
      });
    } else if (window.innerWidth <= 900 && this.state.mobile === false) {
      this.setState({
        desktop: false,
        mobile: true,
        width: "90vw",
      });
    }

    return (
      // // <div style={{ backgroundColor: "#1f1f1f", height: "600%" }}>
      //   {/* {this.state.desktop === true && <SecondaryBar_Menu />}
      //   {this.state.mobile === true && <MainBar_Menu />}
      //   <div id="VanMenuDiv">
      //     <img src={Menu} id="Menu_img" alt="Menu_img" />
      //   </div> */}
      <div id="VanMenuimgDiv">
        <img src={Menu} id="VanMenuimg" alt="Menu_img" />
      </div>
    );
  }
}

export default VanMenu;
