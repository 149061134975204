import React, { Component } from "react";
import ReactMapGL, {
  Marker,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
} from "react-map-gl";
import bbq_store from "../bbq_store.jpeg";
import bbt from "../BBQ_marker2.png";
import FB_Icon from "../Facebook_icon.png";

const TOKEN =
  "pk.eyJ1IjoiZXhhbm92YSIsImEiOiJjazlpdzI1bmMwNnRiM2xsNjhhM3VmbDYyIn0.dIZTSzt7SKRvSSHIkma5Gg";

const fullscreenControlStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  padding: "10px",
};

const navStyle = {
  position: "absolute",
  top: 36,
  left: 0,
  padding: "10px",
};

const scaleControlStyle = {
  position: "absolute",
  bottom: 36,
  left: 0,
  padding: "10px",
};

class BBQ_Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 49.2382,
        longitude: -123.032163,
        zoom: 17,
      },
      width: "40vw",
      height: "40vh",
      load: false,
      load2: false,
    };
  }

  _updateViewport = (viewport) => {
    this.setState({ viewport });
  };

  animation = () => {
    var p = document.getElementById("BBQ_p");
    var innerHeight = window.innerHeight;

    if (p.getBoundingClientRect().top <= innerHeight) {
      p.classList.add("animate__animated", "animate__slideInRight");
    } else {
      p.classList.remove("animate__animated", "animate__slideInRight");
    }
  };

  render() {
    window.addEventListener("scroll", this.animation);

    if (window.innerWidth <= 900) {
      setTimeout(this.animation, 100);
    }

    if (this.state.load === false && window.innerWidth <= 900) {
      this.setState({
        load: true,
        load2: false,
        width: "90vw",
        height: "35vh",
      });
    } else if (this.state.load2 === false && window.innerWidth > 900) {
      this.setState({
        load2: true,
        load: false,
        width: "15vw",
        height: "30vh",
      });
    }
    const { viewport } = this.state;

    return (
      <div className="BBQ_div">
        <div id="Map">
          <div id="BBQaddress">
            <h2>5103 JOYCE ST, VANCOUVER (BBQ BAR)</h2>
          </div>
          <ReactMapGL
            {...viewport}
            width={this.state.width}
            height={this.state.height}
            mapStyle="mapbox://styles/mapbox/dark-v9"
            onViewportChange={this._updateViewport}
            mapboxApiAccessToken={TOKEN}
            style={{ float: "left", marginTop: "0px", paddingLeft: "0px" }}
          >
            <Marker
              longitude={-123.032163}
              latitude={49.2382}
              offsetLeft={-20}
              offsetTop={-10}
            >
              <img src={bbt} alt="marker" className="BBQmarker" />
            </Marker>

            <div style={fullscreenControlStyle}>
              <FullscreenControl />
            </div>
            <div style={navStyle}>
              <NavigationControl />
            </div>
            <div style={scaleControlStyle}>
              <ScaleControl />
            </div>
          </ReactMapGL>

          <div className="BBQ1">
            <img src={bbq_store} alt="BBQ1" id="BBQ1" />

            <div id="BBQ_p">
              <p
                style={{ textAlign: "left", fontSize: "17px", color: "black" }}
              >
                Located right across the West exit of Joyce-Collingwood skytrain
                station <br /> or at the intersection of Joyce St and Vanness
                Ave
              </p>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  color: "black",
                  textDecoration: "underline",
                  marginBottom: "0px",
                }}
              >
                Phone:
              </p>
              <p
                style={{ textAlign: "left", fontSize: "17px", color: "black" }}
              >
                (604) 431-8544
              </p>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "17px",
                  color: "black",
                  textDecoration: "underline",
                  marginBottom: "0px",
                }}
              >
                Hours:
              </p>
              <p
                style={{ textAlign: "left", fontSize: "17px", color: "black" }}
              >
                Everyday from 9 AM - 12 AM
              </p>
              {/* <p
            style={{
              textAlign: "left",
              fontSize: "17px",
              color: "black",
              textDecoration: "underline",
              marginBottom: "5px",
            }}
          >
            Social Media:
          </p>
          <div id="kelowna_social_media">
            <a href="https://www.facebook.com/milkandsugar.bbt.cafe/">
              <img id="FB_contact" alt="FB" src={FB_Icon} />
            </a>
          </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BBQ_Location;
