import React, { Component } from "react";
import "../StyleSheets/Contact.css";
import FB_Icon from "../Facebook_icon.png";
import IG_Icon from "../instagram_icon.png";

class Contact extends Component {
  animation = () => {
    var contact_div = document.getElementById("Contact");
    var location_bottom = contact_div.offsetTop;
    var innerHeight = window.innerHeight;
    var offset = window.pageYOffset;
    var div = document.getElementById("animate");

    if (location_bottom - offset < innerHeight) {
      div.classList.add("animate__animated", "animate__slideInUp");
    } else {
      div.classList.remove("animate__animated", "animate__slideInUp");
    }
  };

  render() {
    window.addEventListener("scroll", this.animation);

    return (
      <div id="Contact_div">
        <div id="animate">
          <h1 id="contact_h1">CONTACT US</h1>
          <div>
            <p id="contact_p">
              Even though we can discuss over bubble tea, there are easier ways
              to contact us.
            </p>
          </div>
          <h4 className="contact_h4" id="contact_h4">
            GET IN TOUCH
          </h4>
          <p id="contact_p">Feel free to reach us at:</p>
          <h4 className="contact_h4">PHONE NUMBERS:</h4>
          <p id="contact_p">
            Vancouver: (604) 559-5477 <br />
            Kelowna: (778) 484-6111
          </p>
          <h4 className="contact_h4">SOCIAL MEDIA:</h4>
          <div id="social_media">
            <a href="https://www.facebook.com/milkandsugarcafe/">
              <img id="FB_contact" alt="FB" src={FB_Icon} />
            </a>
            <a href="https://www.instagram.com/milksugarcafe/?hl=en">
              <img id="IG_contact" alt="IG" src={IG_Icon} />
            </a>
          </div>
        </div>
        <div id="container">
          <div id="footer">
            <p id="contact_p">&copy;2020 Milk &#38; Sugar Café</p>
            <p id="keywords">
              milk and sugar, milk and sugar cafe, milk and sugar kingsway, milk
              and sugar bubble tea, bubble tea, coffee
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
