import React, { Component } from "react";
import img1 from "../Kel-menu/Kel1.jpg";
import img2 from "../Kel-menu/Kel2.jpg";
import img3 from "../Kel-menu/Kel3.jpg";
import img4 from "../Kel-menu/Kel4.jpg";
import img5 from "../Kel-menu/Kel5.jpg";
import img6 from "../Kel-menu/Kel6.jpg";
import img7 from "../Kel-menu/Kel7.jpg";
import img8 from "../Kel-menu/Kel8.jpg";
import img9 from "../Kel-menu/Kel9.jpg";
import img10 from "../Kel-menu/Kel10.jpg";
import img11 from "../Kel-menu/Kel11.jpg";
import img12 from "../Kel-menu/Kel12.jpg";
import img13 from "../Kel-menu/Kel13.jpg";
import img14 from "../Kel-menu/Kel14.jpg";
import img15 from "../Kel-menu/Kel15.jpg";
import img16 from "../Kel-menu/Kel16.jpg";
import img17 from "../Kel-menu/Kel17.jpg";
import img18 from "../Kel-menu/Kel18.jpg";
import img19 from "../Kel-menu/Kel19.jpg";
import img20 from "../Kel-menu/Kel20.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import SecondaryBar_Menu from "./SecondaryBar_menu";
import MainBar_Menu from "./MainBar_Menu";

class KelMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      width: 0,
      desktop: false,
      mobile: false,
    };
  }

  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
    });
  };

  render() {
    if (window.innerWidth > 900 && this.state.desktop === false) {
      this.setState({
        desktop: true,
        mobile: false,
        width: "37vw",
      });
    } else if (window.innerWidth <= 900 && this.state.mobile === false) {
      this.setState({
        desktop: false,
        mobile: true,
        width: "98vw",
      });
    }

    return (
      <div style={{ backgroundColor: "#4e2d08" }}>
        {/* {this.state.desktop === true && <SecondaryBar_Menu />}
        {this.state.mobile === true && <MainBar_Menu />} */}
        <div id="KelCarousel">
          <Carousel
            activeIndex={this.state.index}
            onSelect={this.handleSelect}
            interval={4000}
            indicators={false}
          >
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img1}
                alt="First slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img2}
                alt="Second slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img3}
                alt="Third slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img4}
                alt="Fourth slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img5}
                alt="Fifth slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img6}
                alt="Sixth slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img7}
                alt="Seventh slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img8}
                alt="Eighth slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img9}
                alt="Ninth slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img10}
                alt="Tenth slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img11}
                alt="Eleventh slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img12}
                alt="Twelve slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img13}
                alt="Thirteenth slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img14}
                alt="14th slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img15}
                alt="15th slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img16}
                alt="16th slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img17}
                alt="17th slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img18}
                alt="18th slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img19}
                alt="19th slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="KelCarouselimg"
                src={img20}
                alt="20th slide"
                style={{ width: this.state.width }}
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  }
}

export default KelMenu;
