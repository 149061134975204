import React, { Component } from "react";
import img1 from "../Carousel-img/1.jpg";
import img2 from "../Carousel-img/2.jpg";
import img3 from "../Carousel-img/3.jpg";
import img4 from "../Carousel-img/4.jpg";
import img5 from "../Carousel-img/5.jpg";
import img6 from "../Carousel-img/6.jpg";
import img7 from "../Carousel-img/7.jpg";
import img8 from "../Carousel-img/8.jpg";
import img9 from "../Carousel-img/9.jpg";
import img10 from "../Carousel-img/10.jpg";
import img11 from "../Carousel-img/11.jpg";
import img12 from "../Carousel-img/IG1.jpg";
import img13 from "../Carousel-img/IG2.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";

class PicCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      width: 0,
      desktop: false,
      mobile: false,
    };
  }

  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
    });
  };

  render() {
    if (window.innerWidth > 900 && this.state.desktop === false) {
      this.setState({
        desktop: true,
        mobile: false,
        width: "30vw",
      });
    } else if (window.innerWidth <= 900 && this.state.mobile === false) {
      this.setState({
        desktop: false,
        mobile: true,
        width: "90vw",
      });
    }

    return (
      <Carousel
        activeIndex={this.state.index}
        onSelect={this.handleSelect}
        interval={4000}
        pause={false}
        indicators={false}
      >
        <Carousel.Item>
          <img
            className="Carouselimg"
            src={img1}
            alt="Drink 1"
            style={{ width: this.state.width }}
          />
        </Carousel.Item>
        {/* <Carousel.Item>
          <img
            className="Carouselimg"
            src={img2}
            alt="Second slide"
            style={{ width: this.state.width }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="Carouselimg"
            src={img3}
            alt="Third slide"
            style={{ width: this.state.width }}
          />
        </Carousel.Item> */}
        <Carousel.Item>
          <img
            className="Carouselimg"
            src={img12}
            alt="Instagram1"
            style={{ width: this.state.width }}
          />
        </Carousel.Item>
        {/* <Carousel.Item>
          <img
            className="Carouselimg"
            src={img5}
            alt="Fifth slide"
            style={{ width: this.state.width }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="Carouselimg"
            src={img6}
            alt="Sixth slide"
            style={{ width: this.state.width }}
          />
        </Carousel.Item> */}
        <Carousel.Item>
          <img
            className="Carouselimg"
            src={img13}
            alt="Instagram2"
            style={{ width: this.state.width }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="Carouselimg"
            src={img8}
            alt="Menu"
            style={{ width: this.state.width }}
          />
        </Carousel.Item>
        {/* <Carousel.Item>
          <img
            className="Carouselimg"
            src={img9}
            alt="Ninth slide"
            style={{ width: this.state.width }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="Carouselimg"
            src={img10}
            alt="Tenth slide"
            style={{ width: this.state.width }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="Carouselimg"
            src={img11}
            alt="Eleventh slide"
            style={{ width: this.state.width }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="Carouselimg"
            src={img4}
            alt="Twelve slide"
            style={{ width: this.state.width }}
          />
        </Carousel.Item> */}
        <Carousel.Item>
          <img
            className="Carouselimg"
            src={img7}
            alt="Drink2"
            style={{ width: this.state.width }}
          />
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default PicCarousel;
