import React, { Component } from "react";
import logo from "../logo_transparent.png";

class SecondaryBar_Menu extends Component {
  render() {
    return (
      <div className="NavBar2">
        <div className="logo-container2">
          <a href="/">
            <img
              src={logo}
              className="NavLogo2"
              alt="Logo2"
              style={{ height: "70px", width: "70px" }}
            />
          </a>
        </div>

        <nav className="nav2">
          <ul className="ul2">
            <li className="li21">
              <a id="li21" href="/">
                Home
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default SecondaryBar_Menu;
