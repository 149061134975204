import React, { Component } from "react";
import ReactMapGL, {
  Marker,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
} from "react-map-gl";
import StorePic from "../ms1.JPG";
import bbt from "../bbt.png";
import FB_Icon from "../Facebook_icon.png";
import IG_Icon from "../instagram_icon.png";

const TOKEN =
  "pk.eyJ1IjoiZXhhbm92YSIsImEiOiJjazlpdzI1bmMwNnRiM2xsNjhhM3VmbDYyIn0.dIZTSzt7SKRvSSHIkma5Gg";

const fullscreenControlStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  padding: "10px",
};

const navStyle = {
  position: "absolute",
  top: 36,
  left: 0,
  padding: "10px",
};

const scaleControlStyle = {
  position: "absolute",
  bottom: 36,
  left: 0,
  padding: "10px",
};

class Collingwood_Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 49.233238,
        longitude: -123.033867,
        zoom: 17,
      },
      width: "40vw",
      height: "40vh",
      load: false,
      load2: false,
    };
  }

  _updateViewport = (viewport) => {
    this.setState({ viewport });
  };

  animation = () => {
    var p = document.getElementById("collingwood_p");
    var innerHeight = window.innerHeight;

    if (p.getBoundingClientRect().top <= innerHeight) {
      p.classList.add("animate__animated", "animate__slideInRight");
    } else {
      p.classList.remove("animate__animated", "animate__slideInRight");
    }
  };

  render() {
    window.addEventListener("scroll", this.animation);

    if (window.innerWidth <= 900) {
      setTimeout(this.animation, 100);
    }

    if (this.state.load === false && window.innerWidth <= 900) {
      this.setState({
        load: true,
        load2: false,
        width: "90vw",
        height: "35vh",
      });
    } else if (this.state.load2 === false && window.innerWidth > 900) {
      this.setState({
        load2: true,
        load: false,
        width: "15vw",
        height: "30vh",
      });
    }
    const { viewport } = this.state;

    return (
      <div className="Collingwood_div">
        <div id="Map">
          <div id="address">
            <h2>3365 KINGSWAY, VANCOUVER</h2>
          </div>
          <ReactMapGL
            {...viewport}
            width={this.state.width}
            height={this.state.height}
            mapStyle="mapbox://styles/mapbox/dark-v9"
            onViewportChange={this._updateViewport}
            mapboxApiAccessToken={TOKEN}
            style={{ float: "left", marginTop: "0px" }}
          >
            <Marker
              longitude={-123.033867}
              latitude={49.233238}
              offsetLeft={-20}
              offsetTop={-10}
            >
              <img src={bbt} alt="marker" className="marker" />
            </Marker>

            <div style={fullscreenControlStyle}>
              <FullscreenControl />
            </div>
            <div style={navStyle}>
              <NavigationControl />
            </div>
            <div style={scaleControlStyle}>
              <ScaleControl />
            </div>
          </ReactMapGL>

          <div className="StorePic">
            <img src={StorePic} alt="StorePic" id="StorePic" />
          </div>
        </div>

        <div id="collingwood_p">
          <p style={{ textAlign: "left", fontSize: "17px", color: "black" }}>
            Located right across the street from Honolulu cafe <br /> or the
            intersection of Wessex St and Kingsway
          </p>
          <p
            style={{
              textAlign: "left",
              fontSize: "17px",
              color: "black",
              textDecoration: "underline",
              marginBottom: "0px",
            }}
          >
            Phone:
          </p>
          <p style={{ textAlign: "left", fontSize: "17px", color: "black" }}>
            (604) 559-5477
          </p>
          <p
            style={{
              textAlign: "left",
              fontSize: "17px",
              color: "black",
              textDecoration: "underline",
              marginBottom: "0px",
            }}
          >
            Hours (*Hours may vary during Covid-19):
          </p>
          <p style={{ textAlign: "left", fontSize: "17px", color: "black" }}>
            Everyday from 12 PM - 12 AM
          </p>
          <p
            style={{
              textAlign: "left",
              fontSize: "17px",
              color: "black",
              textDecoration: "underline",
              marginBottom: "5px",
            }}
          >
            Social Media:
          </p>
          <div id="collingwood_social_media">
            <a href="https://www.facebook.com/milkandsugarcafe/">
              <img id="FB_contact" alt="FB" src={FB_Icon} />
            </a>
            <a href="https://www.instagram.com/milksugarcafe/?hl=en">
              <img id="IG_contact" alt="IG" src={IG_Icon} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Collingwood_Location;
