import React, { Component } from "react";
import doordash_logo from "../DoorDash_red3.jpg";
import mask from "../face_mask.png";
import CovidInfo from "../ms3.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import Button from "@material-ui/core/Button";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      width: 0,
      desktop: false,
      mobile: false,
    };
  }

  handleSelect = (selectedIndex, e) => {
    this.setState({
      index: selectedIndex,
    });
  };

  CovidInfoLink = () => {
    window.open(
      "https://www.canada.ca/en/public-health/services/diseases/coronavirus-disease-covid-19.html?utm_campaign=not-applicable&utm_medium=vanity-url&utm_source=canada-ca_coronavirus"
    );
  };

  DoorDashLink = () => {
    window.open(
      "https://www.doordash.com/store/milk-sugar-cafe-vancouver-446027/en-US"
    );
  };

  render() {
    if (window.innerWidth > 900 && this.state.desktop === false) {
      this.setState({
        desktop: true,
        mobile: false,
        width: "900px",
        height: "550px",
      });
    } else if (window.innerWidth <= 900 && this.state.mobile === false) {
      this.setState({
        desktop: false,
        mobile: true,
        width: "97vw",
        height: "30vh",
      });
    }

    return (
      <div>
        {this.state.desktop === true && (
          <Carousel
            activeIndex={this.state.index}
            onSelect={this.handleSelect}
            interval={4000}
            pause={"hover"}
            indicators={false}
          >
            <Carousel.Item>
              <img
                className=""
                src={doordash_logo}
                alt="First slide"
                style={{ width: this.state.width, height: this.state.height }}
              />
              <div
                class="carousel-caption d-none d-md-block"
                style={{
                  bottom: 0,
                }}
              >
                <a
                  href="https://www.doordash.com/business/210255/?utm_source=partner-link&utm_medium=website&utm_campaign=210255&utm_content=white-xl"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="Order Food Delivery with DoorDash"
                  title="Order Food Delivery with DoorDash"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "268px",
                      height: "118px",
                      margin: "0px auto",
                      backgroundImage:
                        "url(https://cdn.doordash.com/media/button/button_white_xl.svg)",
                      color: "transparent",
                    }}
                  >
                    Order Food Delivery with DoorDash
                  </div>
                </a>

                <h5
                  style={{
                    paddingBottom: "10vh",
                    paddingTop: "9vh",
                  }}
                >
                  We are happy to announce that we are collaborating with
                  DoorDash to provide our beloved Milk & Sugar customers with
                  our products! Download the DoorDash App today or order online
                  and get your favourite bubble tea delivered right to your
                  door! Click the button above to get started!{" "}
                </h5>
                {/* <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={this.DoorDashLink}
                >
                  Order At DoorDash
                </Button> */}
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={mask}
                alt="Fask Mask Required"
                style={{ width: this.state.width, height: this.state.height }}
              />
              {/* <div
                class="carousel-caption d-none d-md-block"
                style={{
                  bottom: 0,
                }}
              >
                <h5
                  style={{
                    paddingBottom: "3vh",
                    color: "black",
                    fontSize: "20px",
                    fontFamily: "Montserrat",
                  }}
                >
                  DUE TO COVID-19 RESTRICTIONS, ANYONE WHO WISHES TO VISIT ANY
                  OF OUR LOCATIONS MUST HAVE A FACE MASK ON AT ALL TIMES.
                  FAILURE TO DO SO MAY RESULT IN NO SERVICES.
                </h5>
              </div> */}
            </Carousel.Item>
            <Carousel.Item>
              <img
                className=""
                src={CovidInfo}
                alt="CovidInfo"
                style={{ width: this.state.width, height: this.state.height }}
              />
              <div
                class="carousel-caption d-none d-md-block"
                style={{
                  bottom: 0,
                }}
              >
                <h5
                  style={{
                    paddingBottom: "1vh",
                  }}
                >
                  OUR STORES REMAIN OPEN FOR TAKE-OUT AND WALK-IN SERVICE. ALL
                  STAFF PERSONNEL ARE FOLLOWING PROPER COVID-19 PROTECTIVE
                  MEASURES. FOR MORE INFORMATION ABOUT COVID-19, PLEASE VISIT
                  THE GOVERNMENT WEBSITE FOR INSTRUCTIONS AND PROCEDURES.
                </h5>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={this.CovidInfoLink}
                >
                  FOR MORE COVID-19 INFO
                </Button>
              </div>
            </Carousel.Item>
          </Carousel>
        )}

        {this.state.mobile === true && (
          <Carousel
            activeIndex={this.state.index}
            onSelect={this.handleSelect}
            interval={4000}
            pause={false}
            indicators={false}
          >
            <Carousel.Item>
              <div id="div">
                <a
                  id="a"
                  href="https://www.doordash.com/business/210255/?utm_source=partner-link&utm_medium=website&utm_campaign=210255&utm_content=white-xl"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="Order Food Delivery with DoorDash"
                  title="Order Food Delivery with DoorDash"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "268px",
                      height: "118px",
                      margin: "0px auto",
                      backgroundImage:
                        "url(https://cdn.doordash.com/media/button/button_white_xl.svg)",
                      color: "transparent",
                    }}
                  >
                    Order Food Delivery with DoorDash
                  </div>
                </a>
                <img
                  className=""
                  src={doordash_logo}
                  alt="First slide"
                  style={{ width: this.state.width, height: this.state.height }}
                />
                <div
                  id="diva"
                  class="carousel-caption"
                  style={{
                    bottom: 0,
                    paddingBottom: "0.5vh",
                  }}
                >
                  <h5
                    style={{
                      fontSize: "1.5vh",
                    }}
                  >
                    We are happy to announce that we are collaborating with
                    DoorDash to provide our beloved Milk & Sugar customers with
                    our products! Download the DoorDash App today or order
                    online and get your favourite bubble tea delivered right to
                    your door! Click the button above to get started!{" "}
                  </h5>
                  {/* <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={this.DoorDashLink}
                >
                  Order At DoorDash
                </Button> */}
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className=""
                src={mask}
                alt="Fask Mask Required"
                style={{ width: "95vw", height: this.state.height }}
              />
              {/* <div
                id="maskdiv"
                class="carousel-caption"
                style={{
                  bottom: 0,
                }}
              >
                <h5
                  id="maskh5"
                  style={{
                    color: "black",
                    fontSize: "10px",
                    fontFamily: "Montserrat",
                  }}
                >
                  DUE TO COVID-19 RESTRICTIONS, ANYONE WHO WISHES TO VISIT ANY
                  OF OUR LOCATIONS MUST HAVE A FACE MASK ON AT ALL TIMES.
                  FAILURE TO DO SO MAY RESULT IN NO SERVICES.
                </h5>
              </div> */}
            </Carousel.Item>
            <Carousel.Item>
              <img
                className=""
                src={CovidInfo}
                alt="CovidInfo"
                style={{ width: this.state.width, height: this.state.height }}
              />
              <div
                class="carousel-caption"
                style={{
                  bottom: 0,
                }}
              >
                <h5
                  style={{
                    paddingBottom: "5px",
                    fontSize: "10px",
                  }}
                >
                  OUR STORES REMAIN OPEN FOR TAKE-OUT AND WALK-IN SERVICE. ALL
                  STAFF PERSONNEL ARE FOLLOWING PROPER COVID-19 PROTECTIVE
                  MEASURES. FOR MORE INFORMATION ABOUT COVID-19, PLEASE VISIT
                  THE GOVERNMENT WEBSITE FOR INSTRUCTIONS AND PROCEDURES.
                </h5>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={this.CovidInfoLink}
                >
                  FOR MORE COVID-19 INFO
                </Button>
              </div>
            </Carousel.Item>
          </Carousel>
        )}
      </div>
    );
  }
}

export default Modal;
