import React, { Component } from "react";
import "../StyleSheets/Menu.css";
import InstagramEmbed from "react-instagram-embed";
import Button from "@material-ui/core/Button";
import ReactModal from "react-modal";
import VanMenu from "./VanMenu";
import KelMenu from "./KelMenu";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      desktop: false,
      mobile: false,
      Vancouver: false,
      Kelowna: false,
      desktopModal: "90vh",
      mobileModal: "80vh",
      ModalSize: "90vh",
    };
  }

  animation_menu = () => {
    var menu_p = document.getElementById("menu-p");
    var VanButton = document.getElementById("Van-button");
    var KelButton = document.getElementById("Kel-button");

    var innerHeight = window.innerHeight;

    if (menu_p.getBoundingClientRect().top <= innerHeight) {
      menu_p.classList.add("animate__animated", "animate__fadeIn");
    } else {
      menu_p.classList.remove("animate__animated", "animate__fadeIn");
    }

    if (VanButton.getBoundingClientRect().top <= innerHeight) {
      VanButton.classList.add("animate__animated", "animate__fadeIn");
    } else {
      VanButton.classList.remove("animate__animated", "animate__fadeIn");
    }

    if (KelButton.getBoundingClientRect().top <= innerHeight) {
      KelButton.classList.add("animate__animated", "animate__fadeIn");
    } else {
      KelButton.classList.remove("animate__animated", "animate__fadeIn");
    }
  };

  VancouverMenu = () => {
    // window.location.href = "/VanMenu";
    this.setState({
      Vancouver: true,
      Kelowna: false,
    });
  };

  KelownaMenu = () => {
    // window.location.href = "/KelMenu";
    this.setState({
      Vancouver: false,
      Kelowna: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      Vancouver: false,
      Kelowna: false,
    });
  };

  render() {
    window.addEventListener("scroll", this.animation_menu);
    if (window.innerWidth > 900 && this.state.desktop === false) {
      this.setState({
        desktop: true,
        mobile: false,
        ModalSize: this.state.desktopModal,
      });
    } else if (window.innerWidth <= 900 && this.state.mobile === false) {
      this.setState({
        desktop: false,
        mobile: true,
        ModalSize: this.state.mobileModal,
      });
    }

    return (
      <div id="Menu_div">
        <h1 id="menu_h1">OUR MENU</h1>
        <p id="menu_p">
          Bubble tea café serving fresh fruit and premium ice cream bubble tea
          blends, juices, and shakes!
        </p>

        <div id="menu_location">
          {this.state.desktop === true && (
            <p id="menu-p">
              Come enjoy one of our weekly specials <br />
              or mix and match to your <br />
              personal liking!
            </p>
          )}

          {this.state.mobile === true && (
            <p id="menu-p">
              Come enjoy one of our weekly specials or mix and match to your
              personal liking!
            </p>
          )}

          <ReactModal
            isOpen={this.state.Vancouver}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                zIndex: 999999,
              },
              content: {
                position: "absolute",
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                border: "1px solid #ccc",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                background: "#fff",
                borderRadius: "10px",
                outline: "none",
                padding: "0px",
                height: this.state.ModalSize,
              },
            }}
          >
            <div id="modalDiv">
              <div id="buttonDiv">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={this.handleCloseModal}
                  id="Vanbutton"
                >
                  Close
                </Button>
              </div>
              <VanMenu />
            </div>
          </ReactModal>

          <ReactModal
            isOpen={this.state.Kelowna}
            style={{
              overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.4)",
                zIndex: 999999,
              },
              content: {
                position: "absolute",
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                border: "1px solid #ccc",
                background: "#fff",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "10px",
                outline: "none",
                padding: "0px",
                height: this.state.ModalSize,
              },
            }}
          >
            <div style={{ position: "relative" }}>
              <div id="buttonDiv">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={this.handleCloseModal}
                  style={{ zIndex: 100, position: "absolute", right: 0 }}
                >
                  Close
                </Button>
              </div>
              <div id="KelMenuDiv">
                <KelMenu />
              </div>
            </div>
          </ReactModal>

          <div id="menu-buttons">
            <div id="Van-button">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={this.VancouverMenu}
              >
                Vancouver
              </Button>
            </div>
            <div id="Kel-button">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={this.KelownaMenu}
              >
                Kelowna
              </Button>
            </div>
          </div>
        </div>

        <div id="line">
          <hr></hr>
        </div>

        <div id="instagram">
          <div id="weekly-special">
            <h2 id="special">WEEKLY SPECIALS</h2>

            {this.state.desktop === true && (
              <p id="special-p">
                Specials are updated weekly, follow our instagram
                <br /> and facebook for more information and updates
              </p>
            )}

            {this.state.mobile === true && (
              <p id="special-p">
                Specials are updated weekly, follow our instagram and facebook
                for more <br /> information and updates
              </p>
            )}
          </div>

          {/* <InstagramEmbed
            url='https://www.instagram.com/p/CG5_G7PgbY7/'
            clientAccessToken='<appId>|<clientToken>'
            maxWidth={450}
            hideCaption={true}
            containerTagName="div"
            protocol=""
            injectScript
            onLoading={() => {}}
            onSuccess={() => {}}
            onAfterRender={() => {}}
            onFailure={() => {}}
          /> */}
          <iframe
            id="instagram-embed-0"
            className="instagram-media instagram-media-rendered"
            style={{
              background: "white",
              maxWidth: "465px",
              width: "calc(100% - 2px)",
              borderRadius: "3px",
              border: "1px solid #dbdbdb",
              boxShadow: "none",
              display: "block",
              margin: "0px 0px 12px",
              minWidth: "326px",
              padding: "0px",
            }}
            src="https://www.instagram.com/p/CNaoLlZsLpe/embed?utm_source=ig_embedembed/captioned/"
            scrolling="no"
            data-instgrm-payload-id="instagram-media-payload-0"
            height={750}
            frameBorder={0}
          >
            &lt;span data-mce-type="bookmark" style="display: inline-block;
            width: 0px; overflow: hidden; line-height: 0;"
            class="mce_SELRES_start"&gt;&lt;/span&gt;&lt;span
            data-mce-type="bookmark" style="display: inline-block; width: 0px;
            overflow: hidden; line-height: 0;"
            class="mce_SELRES_start"&gt;&lt;/span&gt;&lt;span
            data-mce-type="bookmark" style="display: inline-block; width: 0px;
            overflow: hidden; line-height: 0;"
            class="mce_SELRES_start"&gt;&lt;/span&gt;
          </iframe>
        </div>
      </div>
    );
  }
}

export default Menu;
