import React, { Component } from "react";
import "../StyleSheets/New_Location.css";
import Collingwood_Location from "./Collingwood_Location";
import Kelowna_Location from "./Kelowna_Location";
import BBQ_Location from "./BBQLocation";

class New_Location extends Component {
  render() {
    return (
      <div className="Location_div" id="Location_div">
        <h1 style={{ textAlign: "center" }}>LOCATIONS (FACE MASKS REQUIRED)</h1>

        <p>Come visit one of our stores and try out one of our tasty drinks!</p>

        <Collingwood_Location />

        <Kelowna_Location />

        <BBQ_Location />
      </div>
    );
  }
}

export default New_Location;
