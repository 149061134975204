import React, { Component } from "react";
import FB_Icon from "../Facebook_icon.png";
import IG_Icon from "../instagram_icon.png";
import logo_trans from "../logo.jpg";

class MainBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      desktop: false,
      mobile: false,
    };
  }

  navSlide = () => {
    var mobile_nav = document.querySelector(".mobile-nav");
    var nav = document.querySelector(".ul1");
    var navLinks = document.querySelectorAll(".ul1 li");

    //Toggle Nav
    nav.classList.toggle("nav-active");

    //Animate Links
    navLinks.forEach((link, index) => {
      if (link.style.animation) {
        link.style.animation = "";
      } else {
        link.style.animation =
          "navLinkFade 1s ease forwards " + index / 45 + "s";
      }
    });

    //Triple Line animation
    mobile_nav.classList.toggle("toggle");
  };

  render() {
    if (window.innerWidth > 900 && this.state.desktop === false) {
      this.setState({
        desktop: true,
        mobile: false,
      });
    } else if (window.innerWidth <= 900 && this.state.mobile === false) {
      this.setState({
        desktop: false,
        mobile: true,
      });
    }

    return (
      <div>
        {this.state.desktop === true && (
          <div id="NavBar">
            <nav className="nav1">
              <div className="mobileLogo">
                <div id="test">
                  <a href="/">
                    <img src={logo_trans} className="NavLogo" alt="Logo" />
                  </a>
                </div>

                <div className="mobile-nav" onClick={this.navSlide}>
                  <div className="line1"></div>
                  <div className="line2"></div>
                  <div className="line3"></div>
                </div>
              </div>
              <ul className="ul1">
                <li>
                  <a href="https://www.instagram.com/milksugarcafe/?hl=en">
                    <img id="IG" alt="IG" src={IG_Icon} />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/milkandsugarcafe/">
                    <img id="FB" alt="FB" src={FB_Icon} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.doordash.com/business/210255/?utm_source=partner-link&utm_medium=website&utm_campaign=210255&utm_content=white-m"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Order Food Delivery with DoorDash"
                    title="Order Food Delivery with DoorDash"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "209px",
                        height: "45px",
                        margin: "0px auto",
                        backgroundImage:
                          "url(https://cdn.doordash.com/media/button/button_white_m.svg)",
                        color: "transparent",
                      }}
                    >
                      Order Food Delivery with DoorDash
                    </div>
                  </a>
                </li>
                <li>
                  <a id="a3" href="#Contact">
                    Contact
                  </a>
                </li>
                <li>
                  <a id="a2" href="#Location">
                    Locations
                  </a>
                </li>
                <li>
                  <a id="a1" href="#Menu">
                    Menu
                  </a>
                </li>
                <li>
                  <a id="a0" href="#About">
                    About
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        )}

        {this.state.mobile === true && (
          <div id="NavBar">
            <div id="test">
              <a href="/">
                <img src={logo_trans} className="NavLogo" alt="Logo" />
              </a>
            </div>
            <nav className="nav1">
              <div className="mobileLogo">
                <div className="mobile-nav" onClick={this.navSlide}>
                  <div className="line1"></div>
                  <div className="line2"></div>
                  <div className="line3"></div>
                </div>
              </div>
              <ul className="ul1">
                <li>
                  <a id="a0" href="#About">
                    About
                  </a>
                </li>
                <li>
                  <a id="a1" href="#Menu">
                    Menu
                  </a>
                </li>
                <li>
                  <a id="a2" href="#Location">
                    Locations
                  </a>
                </li>
                <li>
                  <a id="a3" href="#Contact">
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.doordash.com/business/210255/?utm_source=partner-link&utm_medium=website&utm_campaign=210255&utm_content=red-s"
                    target="_blank"
                    rel="noopener noreferrer"
                    alt="Order Food Delivery with DoorDash"
                    title="Order Food Delivery with DoorDash"
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "142px",
                        height: "35px",
                        margin: "0px auto",
                        backgroundImage:
                          "url(https://cdn.doordash.com/media/button/button_red_s.svg)",
                        color: "transparent",
                      }}
                    >
                      Order Food Delivery with DoorDash
                    </div>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/milkandsugarcafe/">
                    <img id="FB" alt="FB" src={FB_Icon} />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/milksugarcafe/?hl=en">
                    <img id="IG" alt="IG" src={IG_Icon} />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    );
  }
}

export default MainBar;
