import React, { Component } from "react";
import "../StyleSheets/Homepage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainBar from "./MainBar";
import SecondaryBar from "./SecondaryBar";
import About from "./About";
import Menu from "./Menu";
import New_Location from "./New_Location.js";
import Contact from "./Contact";
import { CSSTransition } from "react-transition-group";
import LoadingScreen from "react-loading-screen";
import logo from "../logo_transparent.png";
import Arrow from "../Arrow.png";
import $ from "jquery";
import ReactModal from "react-modal";
import CovidInfo from "../ms3.jpg";
import Button from "@material-ui/core/Button";
import backgroundCamera from "../Background-min.jpg";
import backgroundCamMobile from "../BackgroundMobile-min.jpg";
import Modal from "./Modal.js";
import full_logo from "../logo.jpg";

ReactModal.setAppElement("#root");
var lastScrollTop = 0;

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bar: false,
      loading: true,
      desktop: false,
      mobile: false,
      loader: false,
      i: -1,
      animated_collingwood_hours: false,
      animated_kelowna_hours: false,
      animated_collingwood_map: false,
      animated_kelowna_map: false,
      arrow: false,
      animated: false,
      showModalDesktop: false,
      showModalMobile: false,
    };
  }

  // fake authentication Promise
  authenticate = () => {
    this.setState({
      loading: false,
    });
    setTimeout(this.handleOpenModal, 2000);
  };

  componentDidMount() {
    if (window.innerWidth > 900) {
      setTimeout(this.authenticate, 2000);
    } else {
      setTimeout(this.handleOpenModalMobile, 2000);
    }
  }

  loader = () => {
    this.setState({
      loader: true,
    });
  };

  //sticky navbar items
  myFunction = () => {
    var navbar = document.getElementById("NavBar");
    var sticky = navbar.offsetHeight;
    if (window.pageYOffset >= sticky) {
      this.setState({
        bar: true,
      });
    } else if (window.pageYOffset < sticky) {
      // remove all li childs in navbar
      this.setState({
        bar: false,
      });
    }

    //highlight sticky nav items when we reach section
    var navOffSet = window.pageYOffset + 100; // + 100 to offset for the sticky nav bar
    if (this.state.bar === true) {
      var about_div = document.getElementById("About");
      var menu_div = document.getElementById("Menu");
      var location_div = document.getElementById("Location");
      var contact_div = document.getElementById("Contact");
      var about_nav = document.getElementById("li20");
      var menu_nav = document.getElementById("li21");
      var location_nav = document.getElementById("li22");
      var contact_nav = document.getElementById("li23");

      var about_top = about_div.offsetTop;
      var menu_top = menu_div.offsetTop;
      var location_top = location_div.offsetTop;
      var location_bottom = contact_div.offsetTop;

      if (navOffSet >= about_top && navOffSet < menu_top) {
        about_nav.classList.add("active_section");
        menu_nav.classList.remove("active_section");
        location_nav.classList.remove("active_section");
        contact_nav.classList.remove("active_section");
      } else if (navOffSet >= menu_top && navOffSet < location_top) {
        about_nav.classList.remove("active_section");
        menu_nav.classList.add("active_section");
        location_nav.classList.remove("active_section");
        contact_nav.classList.remove("active_section");
      } else if (navOffSet >= location_top && navOffSet < location_bottom) {
        about_nav.classList.remove("active_section");
        menu_nav.classList.remove("active_section");
        location_nav.classList.add("active_section");
        contact_nav.classList.remove("active_section");
      } else if (navOffSet - 30 >= location_bottom) {
        about_nav.classList.remove("active_section");
        menu_nav.classList.remove("active_section");
        location_nav.classList.remove("active_section");
        contact_nav.classList.add("active_section");
      } else if (navOffSet < menu_top) {
        about_nav.classList.remove("active_section");
        menu_nav.classList.remove("active_section");
        location_nav.classList.remove("active_section");
        contact_nav.classList.remove("active_section");
      }
    }

    if (window.innerWidth <= 900) {
      // mobile animations
      var innerHeight = window.innerHeight;

      //animation for menu mobile
      var menu_p = document.getElementById("menu-p");
      var VanButton = document.getElementById("Van-button");
      var KelButton = document.getElementById("Kel-button");

      if (menu_p.getBoundingClientRect().top <= innerHeight) {
        menu_p.classList.add("animate__animated", "animate__fadeIn");
      } else {
        menu_p.classList.remove("animate__animated", "animate__fadeIn");
      }

      if (VanButton.getBoundingClientRect().top <= innerHeight) {
        VanButton.classList.add("animate__animated", "animate__fadeIn");
      } else {
        VanButton.classList.remove("animate__animated", "animate__fadeIn");
      }

      if (KelButton.getBoundingClientRect().top <= innerHeight) {
        KelButton.classList.add("animate__animated", "animate__fadeIn");
      } else {
        KelButton.classList.remove("animate__animated", "animate__fadeIn");
      }
      //animation for menu mobile ends

      // animation for mobile Contact section
      var contactDiv = document.getElementById("Contact");
      var div = document.getElementById("animate");

      if (contactDiv.getBoundingClientRect().top <= innerHeight) {
        div.classList.add("animate__animated", "animate__slideInUp");
      } else {
        div.classList.remove("animate__animated", "animate__slideInUp");
      }
      //animation for mobile Contact section ends

      // Display Arrow on mobile
      var top_about = document.getElementById("About");
      var about_top_y = top_about.getBoundingClientRect().top;

      if (about_top_y <= 0 && this.state.arrow === false) {
        this.setState({
          arrow: true,
        });
      } else if (about_top_y > 0 && this.state.arrow === true) {
        this.setState({
          arrow: false,
        });
      }

      //Arrow Animations
      var arrow_img = document.getElementById("Arrow_img");

      if (this.state.arrow === true) {
        var st = about_top_y;
        if (st != lastScrollTop) {
          if (st < lastScrollTop) {
            //Downward Scroll
            arrow_img.classList.remove(
              "animate__animated",
              "animate__slideInUp"
            );
            arrow_img.classList.add(
              "animate__animated",
              "animate__slideOutDown"
            );
          } else if (st > lastScrollTop + 60) {
            //Upward Scroll
            arrow_img.classList.remove(
              "animate__animated",
              "animate__slideOutDown"
            );
            arrow_img.classList.add("animate__animated", "animate__slideInUp");
          }
          lastScrollTop = st;
        }
      }
    }
  };

  scrollTop = () => {
    lastScrollTop = 0;
    // Change height:100% into auto
    //$("body").css("height", "auto");

    // $("body").animate({ scrollTop: "0px" }, 300);

    // $("body").css("height", "100%");
    // $("html").css("height", "100%");

    var scrolltotop_scroll_speed = 1500;

    $("html, body").animate(
      {
        scrollTop: 0,
      },
      scrolltotop_scroll_speed
    );
    document.activeElement.blur();
  };

  handleOpenModal = () => {
    this.setState({
      showModalDesktop: true,
      showModalMobile: false,
    });
  };

  handleOpenModalMobile = () => {
    this.setState({
      showModalMobile: true,
      showModalDesktop: false,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModalDesktop: false,
      showModalMobile: false,
    });
  };

  CovidInfoLink = () => {
    window.location.href =
      "https://www.canada.ca/en/public-health/services/diseases/coronavirus-disease-covid-19.html?utm_campaign=not-applicable&utm_medium=vanity-url&utm_source=canada-ca_coronavirus";
  };

  render() {
    window.addEventListener("scroll", this.myFunction);

    if (window.innerWidth <= 900) {
      setTimeout(this.myFunction, 100);
    }

    if (window.innerWidth > 900) {
      if (this.state.desktop === false) {
        this.setState({
          desktop: true,
          mobile: false,
        });
      }
    } else {
      if (this.state.mobile === false) {
        this.setState({
          desktop: false,
          mobile: true,
        });
      }
    }

    return (
      <div>
        {this.state.desktop === true && (
          <LoadingScreen
            loading={this.state.loading}
            bgColor="#4e2d08"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc={logo}
            text="Loading"
          >
            <ReactModal
              isOpen={this.state.showModalDesktop}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  zIndex: 999999,
                },
                content: {
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  border: "1px solid #ccc",
                  background: "#fff",
                  overflow: "auto",
                  WebkitOverflowScrolling: "touch",
                  borderRadius: "10px",
                  outline: "none",
                  padding: "3px",
                },
              }}
            >
              <div style={{ position: "relative" }}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={this.handleCloseModal}
                    style={{ zIndex: 100, position: "absolute", right: 0 }}
                  >
                    Close
                  </Button>
                </div>
                {/* <img
                  style={{
                    width: "900px",
                    height: "550px",
                  }}
                  src={CovidInfo}
                  //src={doordash}
                  alt="CovidInfo"
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: "18px",
                    }}
                  >
                    OUR STORES REMAIN OPEN FOR TAKE-OUT AND WALK-IN SERVICE. ALL
                    STAFF PERSONNEL ARE FOLLOWING PROPER COVID-19 PROTECTIVE
                    MEASURES. FOR MORE INFORMATION ABOUT COVID-19, PLEASE VISIT
                    THE GOVERNMENT WEBSITE FOR INSTRUCTIONS AND PROCEDURES.
                  </p>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={this.CovidInfoLink}
                  >
                    FOR MORE COVID-19 INFO
                  </Button>
                </div> */}
                <div id="carousel2">
                  <Modal />
                </div>
              </div>
            </ReactModal>

            <div id="milk-and-sugar" style={{ display: "none" }}>
              <img src={full_logo} alt="milk-and-sugar" id="milk-and-sugar" />
            </div>

            <div
              id="parallax"
              style={{ backgroundImage: `url(${backgroundCamera})` }}
              alt="background_image"
            >
              <div id="MainBar">
                <MainBar />
              </div>

              <div id="black-logo">
                <img src={logo} id="black-logo-img" />
              </div>

              <div className="SecondaryBar">
                {this.state.bar === true && (
                  <CSSTransition
                    in={this.state.bar}
                    appear={this.state.bar}
                    timeout={400}
                    classNames="fade"
                  >
                    <SecondaryBar />
                  </CSSTransition>
                )}
              </div>
            </div>

            <div id="About" className="About">
              <About />
            </div>

            <div id="Menu" className="Menu">
              <Menu />
            </div>

            <div id="Location" className="Location">
              <New_Location />
            </div>

            <div id="Contact" className="Contact">
              <Contact />
            </div>
          </LoadingScreen>
        )}

        {this.state.mobile === true && (
          <div>
            <ReactModal
              isOpen={this.state.showModalMobile}
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  zIndex: 999999,
                },
                content: {
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  width: "97.2vw",
                  height: "31.1vh",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  background: "#fff",
                  overflow: "auto",
                  WebkitOverflowScrolling: "touch",
                  borderRadius: "10px",
                  outline: "none",
                  padding: "3px",
                },
              }}
            >
              <div style={{ position: "relative" }}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={this.handleCloseModal}
                    style={{ zIndex: 100, position: "absolute", right: 0 }}
                  >
                    Close
                  </Button>
                </div>
                {/* <img
                  style={{
                    width: "95vw",
                    height: "30vh",
                  }}
                  src={CovidInfo}
                  alt="CovidInfo"
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: "10px",
                    }}
                  >
                    OUR STORES REMAIN OPEN FOR TAKE-OUT AND WALK-IN SERVICE. ALL
                    STAFF PERSONNEL ARE FOLLOWING PROPER COVID-19 PROTECTIVE
                    MEASURES. FOR MORE INFORMATION ABOUT COVID-19, PLEASE VISIT
                    THE GOVERNMENT WEBSITE FOR INSTRUCTIONS AND PROCEDURES.
                  </p>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={this.CovidInfoLink}
                  >
                    FOR MORE COVID-19 INFO
                  </Button>
                </div> */}
                <div id="carousel2">
                  <Modal />
                </div>
              </div>
            </ReactModal>

            <div id="milk-and-sugar" style={{ display: "none" }}>
              <img src={full_logo} alt="milk-and-sugar" id="milk-and-sugar" />
            </div>

            <div>
              {/* <div
                id="parallax"
                style={{
                  backgroundImage: `url(${backgroundCamMobile})`,
                }}
              ></div> */}
              <div id="MainBar">
                <MainBar />
              </div>
              <div
                id="noparallax"
                // style={{
                //   backgroundImage: `url(${backgroundCamMobile})`,
                // }}
              >
                <img
                  id="frontpageimg"
                  src={backgroundCamMobile}
                  alt="background_image"
                ></img>
                <div id="black-logo2">
                  <img src={logo} id="black-logo-img2" />
                </div>
              </div>

              {this.state.arrow === true && (
                <div id="Arrow">
                  <img
                    src={Arrow}
                    alt="Arrow"
                    id="Arrow_img"
                    onClick={this.scrollTop}
                  />
                </div>
              )}

              {/* <div id="black-logo">
                <img src={logo} alt="black-logo" id="black-logo-img" />
              </div> */}

              {/* <div id="black-logo2">
                <img src={logo} alt="black-logo" id="black-logo-img2" />
              </div> */}

              <div className="SecondaryBar">
                {this.state.bar === true && (
                  <CSSTransition
                    in={this.state.bar}
                    appear={this.state.bar}
                    timeout={400}
                    classNames="fade"
                  >
                    <SecondaryBar />
                  </CSSTransition>
                )}
              </div>

              <div id="About" className="About">
                <About />
              </div>

              <div id="Menu" className="Menu">
                <Menu />
              </div>

              <div id="Location" className="Location">
                <New_Location />
              </div>

              <div id="Contact" className="Contact">
                <Contact />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Homepage;
