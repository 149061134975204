import React, { Component } from "react";
import PicCarousel from "./PicCarousel";
import About_img from "../About.png";
import About_img_mobile from "../AboutMobile.png";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      desktop: false,
      mobile: false,
    };
  }

  render() {
    if (window.innerWidth > 900 && this.state.desktop === false) {
      this.setState({
        desktop: true,
        mobile: false,
      });
    } else if (window.innerWidth <= 900 && this.state.mobile === false) {
      this.setState({
        desktop: false,
        mobile: true,
      });
    }

    return (
      <div id="about_div">
        <div id="carousel">
          <PicCarousel />
        </div>

        {this.state.desktop === true && (
          <div id="AboutUs">
            {/* Either a phrase in p tag or photoshop and image  */}
            <h2 id="AboutHeader">MILK &#38; SUGAR CAFÉ</h2>
            <br />
            <p id="AboutP">
              At Milk &#38; Sugar, we are very passionate about bringing <br />
              the local community delicious handcrafted drinks while <br />
              providing a friendly atmosphere to our loyal cusotmers. <br />
              Come try out one of our weekly specials or mix and craft <br />
              your very own drink!
            </p>
          </div>
        )}

        {this.state.mobile === true && (
          <div id="AboutUs">
            {/* Either a phrase in p tag or photoshop and image  */}
            <h2 id="AboutHeader">MILK &#38; SUGAR CAFÉ</h2>
            <br />
            <p id="AboutP">
              At Milk &#38; Sugar, we are very passionate about bringing <br />
              the local community delicious handcrafted drinks while <br />
              providing a friendly atmosphere to our loyal cusotmers. <br />
              Come try out one of our weekly specials or mix <br />
              and craft your very own drink!
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default About;
