import React, { Component } from "react";
import logo_trans from "../logo_transparent.png";

class MainBar extends Component {
  navSlide = () => {
    var mobile_nav = document.querySelector(".mobile-nav");
    var nav = document.querySelector(".ul1");
    var navLinks = document.querySelectorAll(".ul1 li");

    //Toggle Nav
    nav.classList.toggle("nav-active");

    //Animate Links
    navLinks.forEach((link, index) => {
      if (link.style.animation) {
        link.style.animation = "";
      } else {
        link.style.animation =
          "navLinkFade 1s ease forwards " + index / 10 + "s";
      }
    });

    //Triple Line animation
    mobile_nav.classList.toggle("toggle");
  };

  render() {
    return (
      <div>
        <div id="NavBar">
          <nav className="nav1">
            <div className="mobileLogo">
              <div id="test">
                <a href="/">
                  <img src={logo_trans} className="NavLogo" alt="Logo" />
                </a>
              </div>

              <div className="mobile-nav" onClick={this.navSlide}>
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
              </div>
            </div>
            <ul className="ul1">
              <li>
                <a id="a1" href="/">
                  Home
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default MainBar;
